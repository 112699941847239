/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Link as GatsbyLink } from "gatsby"
import { TheLayout, BouncingArrow } from "../components"
import theme from "../gatsby-plugin-theme-ui"

const NotFoundPage = () => (
  <TheLayout title="404">
    <h1>Oy... definitely missing something here, too.</h1>
    <p>Let&rsquo;s at least try to find some tiles...</p>
    <GatsbyLink
      to="/tiles"
      sx={{
        fontFamily: theme.fonts.links,
        display: "inline-flex",
        color: "white",
        border: "3px solid #c41230",
        letterSpacing: "2px",
        textTransform: "uppercase",
        bg: "transparent",
        textAlign: "center",
        textDecoration: "none",
        m: 0,
        borderRadius: 0,
        fontSize: "18px",
        fontWeight: "500",
        px: 3,
        py: 3,
        lineHeight: 1,
      }}
    >
      Find tiles <BouncingArrow />
    </GatsbyLink>
  </TheLayout>
)

export default NotFoundPage
